@import url('https://fonts.cdnfonts.com/css/circular-std');

@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6585e8 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #6585e8;
  border-radius: 14px;
  /* border: 3px solid #6585e8; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@tailwind components;
@tailwind utilities;

.primary-gradient {
  background: linear-gradient(165deg, #e90dda -1.38%, #15cff1 73.87%);
}

body {
  background-color: #f5f7fb;
  font-family: 'Circular Std', sans-serif;
  /* overflow: hidden; */
}

#root {
  height: 100%;
}

ul li {
  list-style-type: disc;
  margin-left: 20px;
}
h1 {
  font-size: 1.7rem;
  font-weight: 600;
  color: #1a202c;
}
h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a202c;
}
h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a202c;
}
h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a202c;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
